import Profile from "../Profile/Profile";

const PhonePreviewScreen = ({ user }) => {
  return (
    <div
      className="shadow-xl border-black m-auto bg-white relative overflow-hidden transform -translate-y-10"
      style={{
        width: "290px",
        height: "597px",
        borderRadius: "35px",
        borderWidth: "10px",
      }}
    >
      <div
        className="bg-black mx-auto rounded-b-2xl absolute left-0 right-0 z-50"
        style={{
          width: "95px",
          height: "20px",
        }}
      ></div>

      <Profile user={user} />
    </div>
  );
};

export default PhonePreviewScreen;
