import { SocialIcon } from "react-social-icons";
import useWindowDimensions from "../hooks/useWindowDimensions";

const SocialMediaIcons = ({
  twitter,
  instagram,
  small,
}: {
  twitter: string;
  instagram: string;
  small?: boolean;
}): JSX.Element => {
  const { width } = useWindowDimensions();
  let ICON_SIZE = 34;
  if (!small && width > 500) ICON_SIZE = 38;

  if (!twitter && !instagram) return <div />;
  return (
    <div className="flex flex-row gap-1 md:gap-2.5 justify-center">
      {twitter && (
        <SocialIcon
          key="twitter"
          url={`http://twitter.com/${twitter}`}
          bgColor="#FCFCFC"
          fgColor="#404040"
          className="shadow rounded-full border-gray-250 border"
          style={{
            height: ICON_SIZE,
            width: ICON_SIZE,
            marginTop: 0,
          }}
        />
      )}
      {instagram && (
        <SocialIcon
          key="instagram"
          url={`http://instagram.com/${instagram}`}
          bgColor="#FCFCFC"
          fgColor="#404040"
          className="shadow rounded-full border-gray-250 border"
          style={{
            height: ICON_SIZE,
            width: ICON_SIZE,
            marginTop: 0,
          }}
        />
      )}
    </div>
  );
};

export default SocialMediaIcons;
