import React from "react";

const SelectedToken = ({ token, setSelectedTokens, handleOnItemDeselect }) => {
  return (
    <div
      className="rounded-full shadow-sm"
      style={{
        background: "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
        padding: "2px",
      }}
    >
      <div className="w-max py-1 px-2 rounded-full flex flex-row gap-1 items-center bg-white">
        <span
          className="text-sm font-medium"
          style={{
            background: "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          ${token.symbol}
        </span>
        <div
          className="text-gray-700 cursor-pointer hover:text-red-500"
          onClick={() => handleOnItemDeselect(token)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-2.5 w-2.5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SelectedToken;
