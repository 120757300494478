import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../ui-elements/LoadingSpinner";
import DropDownSearch from "./DropDownSearch";
import SelectedToken from "./SelectedToken";

const EditERC20Section = ({ tokenList, selectedTokens, setSelectedTokens }) => {
  const [searchableTokens, setSearchableTokens] = useState([]);

  useEffect(() => {
    if (tokenList !== "loading" && tokenList.length > 0) {
      setSearchableTokens(
        tokenList.filter(
          (x) =>
            !selectedTokens.some(
              (y) =>
                x.token_address === y.token_address && x.symbol === y.symbol
            )
        )
      );
    }
  }, [tokenList, selectedTokens]);

  const isErc20ArrayFull = () => {
    if (selectedTokens && selectedTokens.length >= 8) return true;
    return false;
  };

  const handleOnItemDeselect = (token) => {
    const tokenIndex = selectedTokens.findIndex((o) => o === token);
    const newSelectedList = [...selectedTokens];
    newSelectedList.splice(tokenIndex, 1);
    setSelectedTokens(newSelectedList);
  };

  const handleTokenClick = (token) => {
    if (!isErc20ArrayFull()) setSelectedTokens([...selectedTokens, token]);
  };

  console.log();

  return (
    <div className="">
      {tokenList === "loading" ? (
        <div className="flex flex-row justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-center flex-wrap mb-3 gap-1">
            {selectedTokens &&
              selectedTokens.map((token, index) => (
                <div key={index}>
                  <SelectedToken
                    token={token}
                    setSelectedTokens={setSelectedTokens}
                    handleOnItemDeselect={handleOnItemDeselect}
                  />
                </div>
              ))}
          </div>
          {isErc20ArrayFull() ? (
            <p className="text-base text-center sm:text-lg text-gray-600 bg-gray-200 py-2 rounded">
              You can only display 8 tokens currently.
            </p>
          ) : (
            <DropDownSearch
              tokenList={searchableTokens}
              setSelectedTokens={setSelectedTokens}
              selectedTokens={selectedTokens}
              {...{ handleTokenClick }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditERC20Section;
