import React, { useState } from "react";
import { useMoralis } from "react-moralis";
import logo from "../../assets/full_logo.svg";

const PassportHeader = () => {
  const { logout, isAuthenticated } = useMoralis();
  let [isNavbarOpen, setIsNavbarOpen] = useState(false);

  return (
    <div className="fixed top-0 w-full bg-clip-padding shadow-sm border-b border-gray-200 bg-white backdrop-filter backdrop-blur-lg bg-opacity-90 z-50">
      <div className="h-12 xs:h-16 overflow-hidden px-4 z-5">
        <div className="flex justify-between items-center w-full h-full">
          <a href="/" className="h-full">
            <img src={logo} className="object-cover h-full py-4" alt="logo" />
          </a>
          <div
            className="md:hidden"
            onClick={() => {
              setIsNavbarOpen(!isNavbarOpen);
            }}
          >
            {isAuthenticated &&
              (!isNavbarOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              ))}
          </div>
          {isAuthenticated && (
            <nav className="hidden md:flex">
              <button onClick={logout}>Logout</button>
            </nav>
          )}
        </div>
      </div>

      {isNavbarOpen && (
        <div className="w-full h-12 text-center items-center justify-center flex md:hidden">
          {isAuthenticated && <button onClick={logout}>Logout</button>}
        </div>
      )}
    </div>
  );
};

export default PassportHeader;
