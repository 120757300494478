import React from "react";
import ERC20Container from "../ERC20Container";
import ProfileNFTCard from "../ProfileNFTCard";
import ProfileFooter from "../ProfileFooter";
import ProfileLinks from "../ProfileLinks";
import ProfilePicture from "../ProfilePicture";
import SocialMediaIcons from "../SocialMediaIcons";
import ShortenedEthAddress from "../ui-elements/ShortenedEthAddress";
import { MoralisObjectSaveData } from "react-moralis";
import { getURLfromNFTMetadata } from "../../utils/eth-utils";
import { NFT } from "../../types";

const Profile = ({
  user,
  preview,
}: {
  user: MoralisObjectSaveData;
  preview: boolean;
}) => {
  const headerNft: any = user.headerNft;
  return (
    <div
      className="bg-gray-25 h-full overflow-y-scroll"
      style={{
        overflow: "overlay",
      }}
    >
      <div
        className="w-full h-32 relative z-0 rounded-b-xl"
        style={{
          background: "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
        }}
      >
        {headerNft && (
          <img
            src={getURLfromNFTMetadata(JSON.parse(headerNft.metadata).image)}
            alt="User header"
            className="w-full h-full object-cover align-middle"
          />
        )}
      </div>
      <div className="text-center max-w-lg mx-auto -mt-16 relative px-4">
        <div className={`mx-auto w-32`}>
          {user.pfpNft === {} && user.pfpFile === {} ? (
            <ProfilePicture />
          ) : (
            <ProfilePicture pfpNft={user.pfpNft} pfpFile={user.pfpFile} />
          )}
        </div>
        <div className="flex flex-col items-center mt-0.5 mb-2">
          <div>
            <h1 className="font-extrabold text-2xl text-gray-900 leading-none">
              {user.displayName}
            </h1>
            <ShortenedEthAddress address={user.ethAddress} />
          </div>
          <div className="mt-0.5 mb-2">
            <ERC20Container erc20s={user.erc20s} small={true} />
          </div>
          <p className="text-gray-800 leading-none text-base mt-0.5 mb-2">
            {user.bio}
          </p>
          <SocialMediaIcons
            twitter={user.twitter}
            instagram={user.instagram}
            small={true}
          />
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <ProfileNFTCard
            NFTs={user.selectedNFTs}
            ethAddress={user.ethAddress}
            small={true}
          />
          <ProfileLinks links={user.links} small={true} />
        </div>
        <ProfileFooter />
      </div>
    </div>
  );
};

export default Profile;
