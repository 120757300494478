import React from "react";
import { NFT } from "../types";
import { getURLfromNFTMetadata } from "../utils/eth-utils";
import ProfilePicture from "./ProfilePicture";

const PFPSelectedNFT = ({ nft }: { nft: NFT }) => {
  const parsedMetadata = JSON.parse(nft.metadata);
  if (parsedMetadata == null) return <></>;

  const nftImageUrl = getURLfromNFTMetadata(
    parsedMetadata ? parsedMetadata.image : undefined
  );
  if (!nftImageUrl) return <></>;

  return (
    <div className={` relative overflow-hidden rounded-sm`}>
      <ProfilePicture pfpNft={nft} />
    </div>
  );
};

export default PFPSelectedNFT;
