import React from "react";

const ProfileLinks = ({
  links,
  small,
}: {
  links: any[];
  small?: boolean;
}): JSX.Element => {
  if (!links) return <div />;
  return (
    <>
      {Object.keys(links).map((item, key) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={links[key].url}
            key={key}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`text-gray-900 py-3 md:py-3 rounded-xl border border-gray-200 shadow bg-white text-sm md:text-base text-left px-4 transform hover:-translate-y-0.5 duration-150 hover:shadow-lg transition-transform font-medium ${
                small && "text-xs"
              }`}
            >
              <p>{links[key].title}</p>
            </div>
          </a>
        );
      })}
    </>
  );
};

export default ProfileLinks;
