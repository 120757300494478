import React from "react";
import { NFT } from "../../types";
import { getURLfromNFTMetadata } from "../../utils/eth-utils";

const verifiedRingStyle = "p-0.75";
const normalRingStyle = "bg-gray-300 p-0.75";

const ProfilePicture = ({
  pfpNft,
  pfpFile,
}: {
  pfpNft?: NFT | null;
  pfpFile?: File | null;
}) => {
  return (
    <div className="rounded-full p-0.75 bg-gray-25">
      <div
        style={{
          background: pfpNft
            ? "-webkit-linear-gradient(360deg, #B916B9, #FF7373)"
            : "none",
        }}
        className={`rounded-full flex-none mx-auto w-full ${
          pfpNft ? verifiedRingStyle : normalRingStyle
        }`}
      >
        <a
          href={
            pfpNft
              ? `http://opensea.io/assets/${
                  pfpNft.token_address + "/" + pfpNft.token_id
                }`
              : `/`
          }
        >
          <div className=" p-0.75 bg-white rounded-full w-full">
            <img
              src={
                pfpNft
                  ? getURLfromNFTMetadata(JSON.parse(pfpNft.metadata).image)
                  : pfpFile
                  ? URL.createObjectURL(pfpFile)
                  : "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
              }
              className="w-full rounded-full object-cover"
              style={{ aspectRatio: "1/1" }}
              alt="pfp"
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProfilePicture;
