export let shortenEthereumAddress = (address: string) => {
  return address.substring(0, 6) + "..." + address.substring(38);
};

export let getURLfromNFTMetadata = (ipfsUrl: string | undefined) => {
  if (!ipfsUrl) return;
  if (ipfsUrl.startsWith("ipfs://"))
    return ipfsUrl.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");
  return ipfsUrl;
};
