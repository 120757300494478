import React from "react";
import { NFT } from "../../../../types";
import { getURLfromNFTMetadata } from "../../../../utils/eth-utils";

const NFTSelectedItem = ({ nft, onDelete }: { nft: NFT; onDelete: any }) => {
  if (!nft.metadata) return <></>;
  const parsedMetadata = JSON.parse(nft.metadata);
  if (parsedMetadata == null) return <></>;

  const nftImageUrl = getURLfromNFTMetadata(
    parsedMetadata ? parsedMetadata.image : undefined
  );
  if (!nftImageUrl) return <></>;

  return (
    <div
      className="relative overflow-hidden min-w-min"
      style={{ width: "105px", minWidth: "105px" }}>
      <img
        alt="nft-background"
        style={{ aspectRatio: "1/1" }}
        className="w-full rounded-sm block object-cover"
        src={nftImageUrl}
      />
      <button
        onClick={onDelete}
        className="absolute top-1 right-1 h-5 w-5 rounded-full bg-black flex justify-center items-center font-bold border border-gray-300 bg-opacity-90 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default NFTSelectedItem;
