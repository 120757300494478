import React from "react";
import { useMoralis } from "react-moralis";
import { useLocation } from "react-router";
import PassportHeader from "../components/PassportHeader";

const UserNotFound = () => {
  const { logout } = useMoralis();
  let location = useLocation();

  return (
    <div className="h-screen">
      <PassportHeader logout={() => logout()} />
      <div className="bg-gray-50 min-h-screen text-center flex flex-col items-center justify-center gap-4">
        <span style={{ fontSize: "5rem", lineHeight: "5rem" }}>🤷🏻‍♂️</span>
        <p className="text-2xl font-bold">
          User
          <code className="bg-gray-200 px-3 mx-3">
            {location.pathname.substring(1)}
          </code>
          Not Found.
        </p>
        <p className="">
          Want to claim this page?{" "}
          <a className="font-semibold underline" href="/">
            Create your profile now.
          </a>
        </p>
      </div>
    </div>
  );
};

export default UserNotFound;
