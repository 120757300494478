import React from "react";

const baseStyle =
  "py-2 px-6 rounded-full inline-flex items-center border mx-auto text-sm leading-6 mb-3 text-white bg-gradient-to-b from-gray-800 to-gray-900 hover:bg-gray-800 transition font-medium shadow hover:from-gray-700 hover:to-gray-800";

const loadingStyle = baseStyle + " from-gray-600 to-gray-700";

const PrimaryButton = ({ text, onClick, disabled, loading }) => {
  return (
    <button
      disabled={disabled}
      className={loading ? loadingStyle : baseStyle}
      onClick={onClick}>
      {loading ? "Loading..." : text}
    </button>
  );
};

export default PrimaryButton;
