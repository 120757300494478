import React from "react";
import { ERC20 } from "../types";

const ERC20Container = ({
  erc20s,
  small,
}: {
  erc20s: ERC20[];
  small?: boolean;
}) => {
  if (!erc20s) return <></>;

  return (
    <div className="flex flex-row justify-center max-w-96 m-auto gap-1 flex-wrap">
      {erc20s &&
        erc20s.map((erc20, index) => (
          <a
            key={index}
            className="m-0 p-0 group hover:opacity-50 transform transition duration-75"
            href={`https://info.uniswap.org/#/tokens/${erc20.token_address}`}
          >
            <div
              className="rounded-full shadow-sm"
              style={{
                background: "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
                padding: "0.125rem",
              }}
            >
              <div
                className={`w-max px-1.5 py-1 rounded-full flex flex-row gap-1 items-center bg-gray-100 ${
                  !small && " md:py-0.5 "
                }`}
              >
                <span
                  className={`text-xs font-medium leading-none ${
                    !small && "md:text-sm"
                  }`}
                  style={{
                    background:
                      "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  ${erc20.symbol}
                </span>
              </div>
            </div>
          </a>
        ))}
    </div>
  );
};

export default ERC20Container;
