import React, { useState, useEffect } from "react";

import { filterNftList } from "../../../../utils/filterNftList";
import NFTHorizontalList from "../NFTHorizontalList";
import SearchSVG from "../../../ui-elements/SearchSVG";
import SearchBar from "../../../ui-elements/SearchBar";
import { NFT } from "../../../../types";

type Props = {
  nftList: NFT[];
  onItemClick: any;
  selectedNFTs?: NFT[];
};

const NFTSearch = ({ nftList, onItemClick, selectedNFTs }: Props) => {
  const [query, setQuery] = useState("");
  const [filteredList, setFilteredList] = useState(nftList);

  useEffect(() => {
    setFilteredList(filterNftList(nftList, query));
  }, [query, nftList]);

  return (
    <>
      <div className="relative text-gray-400 focus-within:text-gray-600 mt-2 mb-3 ">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
          >
            <SearchSVG />
          </button>
        </span>
        <SearchBar onChange={setQuery} />
      </div>
      <NFTHorizontalList
        nftList={filteredList}
        onItemClick={onItemClick}
        selectedNFTs={selectedNFTs}
      />
    </>
  );
};

export default NFTSearch;
