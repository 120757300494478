import React from "react";
import Link from "./Link";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const EditLinks = ({ links, setLinks, deleteLink }) => {
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const newLinks = Array.from(links);
    const link = links[source.index];
    newLinks.splice(source.index, 1);
    newLinks.splice(destination.index, 0, link);
    setLinks(newLinks);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="links">
        {(provided) => (
          <div
            className={`flex flex-col p-2 transition rounded
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {links.map((link, index) => {
              return (
                <Link
                  title={link.title}
                  url={link.url}
                  deleteLink={deleteLink}
                  setLinks={setLinks}
                  links={links}
                  id={link.id}
                  key={link.id}
                  index={index}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default EditLinks;
