import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { shortenEthereumAddress } from "../../utils/eth-utils";

const ShortenedEthAddress = ({ address }) => {
  const [showCopy, setShowCopy] = useState(false);

  const [hasCopied, setHasCopied] = useState(false);

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => setHasCopied(false), 1200);
    }
  }, [hasCopied]);

  if (address === null) return <></>;
  return (
    <div className="relative">
      <div
        className={`absolute left-0 right-0 -top-9 bg-black w-20 text-white mx-auto rounded-lg py-1 px-2 font-bold transform duration-75 shadow-sm ${
          showCopy || hasCopied ? "opacity-90" : "opacity-0"
        }`}
      >
        <p>{hasCopied ? "Copied!" : "Copy"}</p>
      </div>

      <p
        className="text-gray-400 text-base leading-none hover:text-gray-800 cursor-pointer transform duration-75 inline"
        onClick={() => {
          navigator.clipboard.writeText(address);
          setHasCopied(true);
        }}
        onMouseOver={() => setShowCopy(true)}
        onMouseOut={() => setShowCopy(false)}
      >
        {shortenEthereumAddress(address)}
      </p>
    </div>
  );
};

export default ShortenedEthAddress;
