import React from "react";
import FormElement from "../ui-elements/FormElement";

const UserDetailForm = ({ submitEditProfileForm, formData, setFormData }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitEditProfileForm();
      }}>
      <FormElement
        label="Display Name"
        required
        type="text"
        placeholder="What do you go by?"
        value={formData.displayName}
        setValue={(input) => {
          setFormData({ ...formData, displayName: input });
        }}
        maxLength={50}
      />
      <FormElement
        label="Bio"
        type="textarea"
        placeholder="Tell your story."
        value={formData.bio}
        setValue={(input) => {
          setFormData({ ...formData, bio: input });
        }}
        maxLength={120}
      />
      <FormElement
        label="Email"
        type="email"
        placeholder="Optional. We keep this private."
        value={formData.email}
        setValue={(input) => {
          setFormData({ ...formData, email: input });
        }}
      />
      <FormElement
        label="Twitter"
        type="input"
        placeholder="@username"
        value={formData.twitter}
        setValue={(input) => {
          setFormData({ ...formData, twitter: input });
        }}
        maxLength={15}
      />
      <FormElement
        label="Instagram"
        type="input"
        placeholder="@username"
        value={formData.instagram}
        setValue={(input) => {
          setFormData({ ...formData, instagram: input });
        }}
        maxLength={30}
      />
    </form>
  );
};

export default UserDetailForm;
