import React from "react";
import LoadingSpinner from "../../ui-elements/LoadingSpinner";
import NFTSelectionContainer from "../NFTSelectionPanel";
import { NFT } from "../../../types";

const EditNFTsSection = ({
  nftList,
  selectedNFTs,
  setSelectedNFTs,
}: {
  nftList: NFT[] | "loading";
  selectedNFTs: NFT[];
  setSelectedNFTs: any;
}) => {
  return (
    <div>
      {nftList === "loading" ? (
        <div className="w-full flex flex-row justify-center mb-4 mt-2">
          <LoadingSpinner />
        </div>
      ) : nftList.length > 0 ? (
        <NFTSelectionContainer
          nftList={nftList}
          selectedNFTs={selectedNFTs}
          setSelectedNFTs={setSelectedNFTs}
        />
      ) : (
        <div className="flex items-center justify-center mt-2 mb-6">
          <div className="bg-red-100 p-4 px-6 rounded-xl">
            <p className="font-semibold text-red-500 text-center">
              You have no NFTs!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditNFTsSection;
