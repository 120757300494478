import React from "react";
import EditProfileHeader from "../../ui-elements/EditProfileHeader";
import UserDetailForm from "../UserDetailForm";

const EditUserDetailsSection = ({
  submitEditProfileForm,
  formData,
  setFormData,
}) => {
  return (
    <div className="">
      <UserDetailForm
        submitEditProfileForm={submitEditProfileForm}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};

export default EditUserDetailsSection;
