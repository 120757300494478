import React from "react";
import EditProfileHeader from "./EditProfileHeader";

type EditPanelProps = {
  children: React.ReactNode;
  text: string;
  subtext?: string;
};

const EditPanel = ({
  children,
  text = "Choose a profile picture.",
  subtext,
}: EditPanelProps) => {
  return (
    <div className="flex flex-col gap-4">
      <EditProfileHeader text={text} subtext={subtext} />
      <div>{children}</div>
    </div>
  );
};

export default EditPanel;
