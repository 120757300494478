type Props = {
  text: string;
  subtext?: string;
};

const EditProfileHeader = ({ text, subtext }: Props) => {
  return (
    <div className="flex flex-col gap-0.5">
      <div className="text-center mx-auto w-full items-center justify-center">
        <h2 className="font-semibold text-base leading-none pb-0.5 text-gray-900">
          {text}
        </h2>
      </div>
      {subtext && (
        <p className="text-sm text-center text-gray-800 font-normal leading-none">
          {subtext}
        </p>
      )}
    </div>
  );
};

export default EditProfileHeader;
