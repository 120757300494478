const baseStyle =
  "font-semibold py-2 px-6  rounded-full inline-flex items-center mx-auto text-sm transition duration-75 tracking-tight text-gray-900";

type Props = {
  text: string;
  onClick: React.MouseEventHandler;
  variant?: string;
  disabled?: boolean;
};

const SecondaryButton = ({ text, onClick, variant, disabled }: Props) => {
  return (
    <button
      disabled={disabled}
      className={
        baseStyle +
        (variant !== "borderless"
          ? " hover:border-gray-900 hover:shadow border-gray-200 shadow-sm border-2"
          : " hover:text-gray-500 ")
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
