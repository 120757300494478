import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Profile from "./pages/Profile";
import { useMoralis } from "react-moralis";
import LandingPage from "./pages/LandingPage";
import Settings from "./pages/Settings";

function App() {
  const { isAuthenticated } = useMoralis();

  return (
    <Router>
      <Switch>
        <Route path="/settings">
          {isAuthenticated ? <Settings /> : <Redirect to="/" />}
        </Route>
        <Route path="/:ethAddress">
          <Profile />
        </Route>
        <Route path="/">
          {isAuthenticated ? <Redirect to={"/settings"} /> : <LandingPage />}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
