import { NFT } from "../../types";
import { getURLfromNFTMetadata } from "../../utils/eth-utils";

const NFTImage = ({ nft }: { nft: NFT }): JSX.Element => {
  let src = getURLfromNFTMetadata(JSON.parse(nft.metadata).image);

  return (
    <div className="overflow-hidden shadow-sm rounded-sm">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`http://opensea.io/assets/${
          nft.token_address + "/" + nft.token_id
        }`}>
        <img
          className="w-full object-cover"
          style={{
            aspectRatio: "1",
          }}
          src={src}
          alt={JSON.parse(nft.metadata).name}
        />
      </a>
    </div>
  );
};

export default NFTImage;
