import React from "react";
import EditLinks from "./EditLinks";
import SecondaryButton from "../../ui-elements/SecondaryButton";
import { v4 as uuidv4 } from "uuid";

const EditLinksSection = ({ order, links, deleteLink, setLinks }) => {
  let userHasLinks = links.length > 0;

  const addLink = () => {
    let newLink = { title: "", url: "", id: uuidv4() };

    if (userHasLinks) {
      setLinks([...links, newLink]);
    } else {
      setLinks([newLink]);
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <SecondaryButton text={"Add Link"} onClick={() => addLink()} />
        <EditLinks links={links} deleteLink={deleteLink} setLinks={setLinks} />
      </div>
    </div>
  );
};

export default EditLinksSection;
