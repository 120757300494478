import React, { useState, useEffect } from "react";

import { useParams } from "react-router";
import ProfilePicture from "../components/ProfilePicture";

import { MoralisObjectSaveData, useMoralisQuery } from "react-moralis";
import ProfileNFTCard from "../components/ProfileNFTCard";
import SocialMediaIcons from "../components/SocialMediaIcons";
import ProfileLinks from "../components/ProfileLinks";
import LoadingSpinner from "../components/ui-elements/LoadingSpinner";
import UserNotFound from "./UserNotFound";
import ShortenedEthAddress from "../components/ui-elements/ShortenedEthAddress";
import ERC20Container from "../components/ERC20Container";
import ProfileFooter from "../components/ProfileFooter";
import { NFT } from "../types";
import { getURLfromNFTMetadata } from "../utils/eth-utils";

const Profile = (): JSX.Element => {
  const [fetchedUser, setUser] = useState<MoralisObjectSaveData>({});
  const [startedFetching, setStartedFetching] = useState<boolean>(false);

  const params = useParams<{ ethAddress: string }>();

  const { data, isFetching, isLoading } = useMoralisQuery(
    "PublicUser",
    (query) => query.equalTo("ethAddress", params.ethAddress.toLowerCase())
  );

  useEffect(() => {
    const fetchUser = async () => {
      if (data.length > 0) {
        setUser(data[0]);
      }
    };
    fetchUser();
  }, [data]);

  useEffect(() => {
    if (isFetching || isLoading) setStartedFetching(true);
  }, [isFetching, isLoading]);

  // console.log(fetchedUser.get("erc20s"));
  if (isLoading || isFetching || !startedFetching)
    return (
      <div className="flex w-full h-screen justify-center items-center">
        <LoadingSpinner />
      </div>
    );

  if (fetchedUser == null) return <UserNotFound />;

  const headerNft: NFT | null = fetchedUser.get("headerNft");

  return (
    <div>
      <div className="bg-gray-25 min-h-screen">
        <div className="w-full bg-gray-500 rounded-b-xl h-52 overflow-hidden relative z-0">
          {headerNft && (
            <a
              href={`http://opensea.io/assets/${
                headerNft.token_address + "/" + headerNft.token_id
              }`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div
                style={{
                  background:
                    "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
                }}
                className={`flex-none mx-auto w-full h-full p-0.75  rounded-b-xl`}
              >
                <img
                  src={getURLfromNFTMetadata(
                    JSON.parse(headerNft.metadata).image
                  )}
                  alt="User header"
                  className="w-full h-full object-cover align-middle rounded-b-xl"
                />
              </div>
            </a>
          )}
        </div>
        <div className="text-center h-screen max-w-lg md:max-w-xl mx-auto -mt-16 relative px-6">
          <div className={`mx-auto w-32 md:w-36`}>
            <ProfilePicture
              pfpNft={fetchedUser.get("pfpNft")}
              pfpFile={fetchedUser.get("pfpFile")}
            />
          </div>
          <div className="flex flex-col items-center mt-0.5 mb-2 md:mt-1 md:mb-3">
            <div>
              <h1 className="font-extrabold text-2xl text-gray-900 leading-none">
                {fetchedUser.get("displayName")}
              </h1>
              <ShortenedEthAddress address={fetchedUser.get("ethAddress")} />
            </div>
            <div className="mt-0.5 mb-2 md:mt-1">
              <ERC20Container erc20s={fetchedUser.get("erc20s")} />
            </div>
            <p className="text-gray-800 leading-none text-base mt-0.5 mb-2">
              {fetchedUser.get("bio")}
            </p>
            <SocialMediaIcons
              twitter={fetchedUser.get("twitter")}
              instagram={fetchedUser.get("instagram")}
            />
          </div>
          <div className="w-full flex flex-col gap-2 md:gap-2.5">
            <ProfileNFTCard
              NFTs={fetchedUser.get("profileNFTs")}
              ethAddress={fetchedUser.get("ethAddress")}
            />
            <ProfileLinks links={fetchedUser.get("links")} />
          </div>
          <ProfileFooter />
        </div>
      </div>
    </div>
  );
};

export default Profile;
