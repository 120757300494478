import React from "react";

const ProfileFooter = () => {
  return (
    <div className="flex flex-row justify-between my-6 border-t text-sm text-gray-600 pt-3">
      <a href="/">
        <img
          src="/full_logo.png"
          className="h-6 object-cover"
          alt="Passport Logo"
        />
      </a>
      <p>
        <a href="/" className="hover:text-gray-800 transform transition">
          Create your own profile
        </a>
      </p>
    </div>
  );
};

export default ProfileFooter;
