interface Props {
  query?: string;
  placeholder?: string;
  onChange: (e: string) => void;
  setIsSelected?: (val: boolean) => void;
}

const SearchBar = ({
  query,
  onChange,
  placeholder = "Search...",
  setIsSelected,
}: Props) => {
  return (
    <div>
      <input
        type="text"
        className={`py-1.5 tracking-tight text-base text-gray-600 rounded-full shadow-sm pl-9 pr-1 focus:outline-none focus:bg-white border border-gray-300 focus:border-gray-900 focus:text-gray-900 w-full ${
          query && " "
        }`}
        placeholder={placeholder}
        autoComplete="off"
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => {
          setIsSelected(true);
        }}
        value={query}
      />
    </div>
  );
};

export default SearchBar;
