import React, { useEffect, useState } from "react";
import NFTSearch from "./NFTSearch";
import { NFT } from "../../../types";
import NFTHorizontalList from "./NFTHorizontalList";

const NFTSelectionPanel = ({
  nftList,
  selectedNFTs,
  setSelectedNFTs,
}: {
  nftList: NFT[];
  selectedNFTs: NFT[];
  setSelectedNFTs: any;
}) => {
  const [nftListWithSelection, setNftListWithSelection] = useState<NFT[]>([]);

  useEffect(() => {
    setNftListWithSelection(nftList);
  }, [nftList]);

  const handleOnItemClick = (tokenAddress: string, tokenId: string) => {
    if (typeof nftListWithSelection === "string") return;
    const indexOfClickedNFT: number = nftListWithSelection.findIndex(
      (nft) => nft.token_address === tokenAddress && nft.token_id === tokenId
    );

    if (!selectedNFTs) {
      setSelectedNFTs([nftList[indexOfClickedNFT]]);
      return;
    }

    let isNFTAlreadySelected: boolean =
      selectedNFTs.findIndex(
        (nft) => nft.token_address === tokenAddress && nft.token_id === tokenId
      ) >= 0;

    if (isNFTAlreadySelected) {
      handleOnItemDeselect(tokenAddress, tokenId);
      return;
    }

    if (selectedNFTs.length > 2) return;

    const newSelectedList = [...selectedNFTs];

    newSelectedList.push(nftListWithSelection[indexOfClickedNFT]);

    setSelectedNFTs(newSelectedList);
  };

  const handleOnItemDeselect = (tokenAddress, tokenId) => {
    const indexOfClickedNft = selectedNFTs.findIndex(
      (o) => o.token_address === tokenAddress && o.token_id === tokenId
    );
    const newSelectedList = [...selectedNFTs];

    const newNftListWithSelection = [...nftListWithSelection];

    const indexOfClickedNftOnMainList = nftListWithSelection.findIndex(
      (nft) => nft.token_address === tokenAddress && nft.token_id === tokenId
    );
    newNftListWithSelection[indexOfClickedNftOnMainList] = {
      ...nftList[indexOfClickedNftOnMainList],
    };

    newSelectedList.splice(indexOfClickedNft, 1);

    setNftListWithSelection(newNftListWithSelection);
    setSelectedNFTs(newSelectedList);
  };

  return (
    <div>
      <NFTSearch
        nftList={nftListWithSelection}
        onItemClick={handleOnItemClick}
        selectedNFTs={selectedNFTs}
      />
      <NFTHorizontalList
        selectedNFTs={selectedNFTs}
        nftList={selectedNFTs}
        onItemClick={handleOnItemDeselect}
        type="selected-list"
      />
    </div>
  );
};

export default NFTSelectionPanel;
