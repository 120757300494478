import { ERC20 } from "../types";
import { NFT } from "../types";

export const filterNftList = (nftList: NFT[], query: string): NFT[] => {
  return nftList.filter((nft) =>
    nft.name.length > 0 && nft.symbol.length > 0
      ? nft.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
        nft.symbol.toUpperCase().indexOf(query.toUpperCase()) > -1
      : []
  );
};

export const filterTokenList = (tokenList: ERC20[], query: string): ERC20[] => {
  return tokenList.filter(
    (token) =>
      token.name.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
      token.symbol.toUpperCase().indexOf(query.toUpperCase()) > -1
  );
};
