import React, { useState } from "react";
import { getURLfromNFTMetadata } from "../../../utils/eth-utils";
import NFTSearch from "../NFTSelectionPanel/NFTSearch";
import SecondaryButton from "../../ui-elements/SecondaryButton";
import { NFT } from "../../../types";

const Controls = ({
  nftList,
  setNftDrawerOpen,
  nftDrawerOpen,
  uploadHeaderImage,
}) => {
  let isButtonDisabled = nftList.length === 0;

  let onUploadImageClick = () => {
    document.getElementById("uploadHeaderImageButton").click();
    document.getElementById("uploadHeaderImageButton").onchange = () => {
      let headerImageFile = (
        document.getElementById("uploadHeaderImageButton") as HTMLInputElement
      ).files[0];
      uploadHeaderImage(headerImageFile);
    };
  };

  return (
    <div className="text-center flex flex-col w-full h-full justify-center">
      <SecondaryButton
        onClick={() => setNftDrawerOpen(!nftDrawerOpen)}
        disabled={isButtonDisabled}
        text={isButtonDisabled ? "You have no NFTs!" : "Select NFT"}
      ></SecondaryButton>
      <input
        type="file"
        hidden
        id="uploadHeaderImageButton"
        accept="image/png,image/jpeg"
      />
      <SecondaryButton
        text={"Upload Image"}
        variant="borderless"
        onClick={() => onUploadImageClick()}
      ></SecondaryButton>
    </div>
  );
};

const HeaderNFTSelector = ({
  nftList,
  setNft,
  setNftDrawerOpen,
}: {
  nftList: NFT[];
  setNft: (NFT) => void;
  setNftDrawerOpen: (boolean) => void;
}) => {
  const handleOnItemClick = (tokenAddress, tokenId) => {
    const indexOfClickedNft = nftList.findIndex(
      (nft) => nft.token_address === tokenAddress && nft.token_id === tokenId
    );

    setNft(nftList[indexOfClickedNft]);
    setNftDrawerOpen(false);
  };

  return (
    <div>
      <NFTSearch nftList={nftList} onItemClick={handleOnItemClick} />
    </div>
  );
};

const EditHeaderPanel = ({
  nftList,
  setHeaderNft,
  headerNft,
  headerFile,
  setHeaderFile,
}) => {
  const [nftDrawerOpen, setNftDrawerOpen] = useState(false);
  const parsedMetadata = headerNft ? JSON.parse(headerNft.metadata) : null;

  const nftImageUrl = getURLfromNFTMetadata(
    parsedMetadata ? parsedMetadata.image : undefined
  );

  const uploadHeaderImage = (file: File) => {
    setHeaderFile(file);
    setHeaderNft(null);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full rounded-md p-0.75">
        <div
          className="w-full h-full rounded-md"
          style={{
            background: "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
            aspectRatio: "3.5/1",
          }}
        >
          {nftImageUrl ? (
            <div className="w-full h-full p-0.75 rounded-md">
              <img
                src={nftImageUrl}
                className="w-full h-full object-cover rounded-md border-white"
                alt="NFT Header"
              />
            </div>
          ) : (
            headerFile && (
              <img
                src={URL.createObjectURL(headerFile)}
                className="w-full h-full object-cover rounded-md border-white"
                alt="NFT Header"
              />
            )
          )}
        </div>
      </div>
      <Controls
        {...{ nftList }}
        {...{ setNftDrawerOpen }}
        {...{ nftDrawerOpen }}
        {...{ uploadHeaderImage }}
      />
      {nftDrawerOpen && (
        <HeaderNFTSelector
          nftList={nftList}
          setNftDrawerOpen={setNftDrawerOpen}
          setNft={setHeaderNft}
        />
      )}
    </div>
  );
};

export default EditHeaderPanel;
