import React, { useState } from "react";
import NFTSearch from "./Settings/NFTSelectionPanel/NFTSearch";
import PFPSelectedNFT from "./PFPSelectedNFT";
import { NFT } from "../types";

const PFPNFTSelector = ({
  nftList,
  setPfpNft,
  setNftDrawerOpen,
}: {
  nftList: NFT[];
  setPfpNft: (NFT) => void;
  setNftDrawerOpen: (boolean) => void;
}) => {
  const [selectedNft, setSelectedNft] = useState(null);

  const handleOnItemClick = (tokenAddress, tokenId) => {
    const indexOfClickedNft = nftList.findIndex(
      (nft) => nft.token_address === tokenAddress && nft.token_id === tokenId
    );

    setSelectedNft(nftList[indexOfClickedNft]);
  };

  return (
    <div>
      <NFTSearch nftList={nftList} onItemClick={handleOnItemClick} />
      {selectedNft && (
        <div className="flex flex-row justify-center gap-6 mt-4">
          <div style={{ maxWidth: "150px" }}>
            <PFPSelectedNFT nft={selectedNft} />
          </div>
          <div className="text-center flex flex-col justify-center">
            <button
              className={`bg-black text-white text-base font-bold py-2.5 rounded-full px-6`}
              onClick={() => {
                setPfpNft(selectedNft);
                setNftDrawerOpen(false);
              }}
            >
              Confirm NFT
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PFPNFTSelector;
