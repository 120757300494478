import { NFT } from "../../types";
import NFTImage from "../NFTImage";

const ProfileNFTCard = ({
  NFTs,
  ethAddress,
  small,
}: {
  NFTs: NFT[];
  ethAddress: string;
  small?: boolean;
}): JSX.Element => {
  if (!NFTs || NFTs.length === 0) return <></>;
  return (
    <div className="bg-white px-3 py-4 font-medium rounded-xl shadow justify-evenly flex flex-col gap-3 border border-gray-200 ">
      <div className="flex flex-row justify-between items-center px-1">
        <span
          className={`text-base text-left leading-none ${small && "text-sm"}`}
          style={{
            background: "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          NFT Showcase
        </span>
        <a
          href={`http://opensea.io/` + ethAddress}
          className="text-gray-500 hover:text-gray-700 transform transition"
        >
          <span className="text-sm font-normal flex flex-row items-center leading-none text-right">
            View All NFTs{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </a>
      </div>
      <div className="flex flex-row gap-1">
        {NFTs &&
          NFTs.map((nft, index) => (
            <div
              key={index}
              style={{ flexBasis: "33%" }}
              className="transform hover:-translate-y-0.5 duration-150 hover:shadow-down transition-transform"
            >
              <NFTImage nft={nft} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProfileNFTCard;
