import React from "react";
import NFTSearchItem from "../NFTSearchItem";
import NFTSelectedItem from "../NFTSelectedItem";
import { NFT } from "../../../../types";

const NFTHorizontalList = ({
  nftList,
  onItemClick,
  selectedNFTs,
  type = "search-list",
}: {
  nftList: NFT[];
  selectedNFTs: NFT[];
  type?: string;
  onItemClick: any;
}) => {
  return (
    <div
      className={`flex flex-row items-start overflow-x-auto gap-1 mb-2 justify-start  ${
        type === "selected-list" && "justify-center"
      }`}
    >
      {nftList &&
        nftList.map((item, index) => {
          if (type === "selected-list")
            return (
              <div key={index}>
                <NFTSelectedItem
                  onDelete={() => {
                    onItemClick(item.token_address, item.token_id);
                  }}
                  nft={item}
                />
              </div>
            );
          else {
            let nftIsSelected =
              selectedNFTs &&
              selectedNFTs.filter(
                (nft) =>
                  nft.token_address === item.token_address &&
                  nft.token_id === item.token_id
              ).length > 0;

            return (
              <div key={index}>
                <NFTSearchItem
                  onClick={() => {
                    onItemClick(item.token_address, item.token_id);
                  }}
                  nftIsSelected={nftIsSelected}
                  nft={item}
                />
              </div>
            );
          }
        })}
    </div>
  );
};

export default NFTHorizontalList;
