import React, { useState, useEffect } from "react";
import FormElement from "../../ui-elements/FormElement";
import { Draggable } from "react-beautiful-dnd";

const DragHandleSection = (props) => {
  return (
    <div
      className=" px-2 py-1 col-span-1 flex h-full"
      {...props.dragHandleProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-7 w-7 m-auto text-gray-400"
        viewBox="0 0 20 20 "
        fill="currentColor">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </div>
  );
};

const DeleteLinkButton = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        />
      </svg>
    </button>
  );
};

const Link = ({ url, id, index, title, deleteLink, setLinks, links }) => {
  const [inputValues, setInputValues] = useState({ title: title, url: url });

  let setTitle = (title) => {
    setInputValues({ ...inputValues, title: title });
  };

  let setURL = (url) => {
    setInputValues({ ...inputValues, url: url });
  };

  useEffect(() => {
    let newLinks = links;
    let link = newLinks[index];
    newLinks[index] = { ...link, ...inputValues };
    setLinks(newLinks);
  }, [inputValues]);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <div
            className={`grid grid-flow-col grid-cols-8 md:grid-cols-11 transition mb-1`}>
            <div {...provided.dragHandleProps}>
              <DragHandleSection />
            </div>
            <div
              className={`rounded-md border bg-white border-gray-200 w-full py-1 px-3.5 col-span-7 md:col-span-10 ${
                snapshot.isDragging ? "shadow" : "shadow-sm"
              }`}>
              <div className="">
                <FormElement
                  label="Link Title"
                  placeholder="Title"
                  type="text"
                  value={inputValues.title}
                  setValue={setTitle}
                  maxLength={100}
                  required={true}
                />
              </div>
              <div>
                <FormElement
                  label="URL"
                  placeholder="URL"
                  value={inputValues.url}
                  setValue={setURL}
                  setInputValues={setInputValues}
                  inputValues={inputValues}
                  type="text"
                  required={true}
                />
              </div>
              <div className="flex justify-end text-gray-400 m-2 text-sm">
                <DeleteLinkButton onClick={() => deleteLink(id)} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Link;
