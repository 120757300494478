import React, { useState } from "react";

const inputStyle = `border rounded-md pl-3 py-1.5 text-base mb-2 text-gray-900 border-gray-300 leading-tighter`;
const focusedStyle = "";

const FormElement = ({
  label,
  value,
  disabled = false,
  required = false,
  type,
  placeholder,
  setValue,
  maxLength = null,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <label className="text-gray-900 text-xs font-bold bg-white px-1 translate-y-2 translate-x-1.5 transform leading-tighter">
          {label}
          {required && "*"}
        </label>
        {/* {maxLength && value && isFocused && (
          <span className={`text-sm`}>{value.length + " / " + maxLength}</span>
        )} */}
      </div>
      {type === "textarea" ? (
        <textarea
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          required={required}
          className={inputStyle + (isFocused && focusedStyle)}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => {
            setIsFocused(true);
          }}
          onBlur={(e) => setIsFocused(false)}
        />
      ) : (
        <input
          type={type}
          value={value}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          className={inputStyle}
          maxLength={maxLength}
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => {
            setIsFocused(true);
          }}
          onBlur={(e) => setIsFocused(false)}
        />
      )}
    </div>
  );
};

export default FormElement;
