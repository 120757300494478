import React, { Fragment, useRef, useState } from "react";
import { useMoralis } from "react-moralis";
import PassportHeader from "../components/PassportHeader";
import { Dialog, Transition } from "@headlessui/react";
import LoadingSpinner from "../components/ui-elements/LoadingSpinner";

const buttonStyle = `bg-grey-light transition duration-75 hover:border-gray-900 text-grey-900 font-medium py-2 px-4 rounded-full inline-flex items-center text-base text-center border-2 border-gray-200 w-full bg-white`;

const LandingPage = () => {
  const { logout, isAuthenticated, authenticate, isAuthenticating } =
    useMoralis();
  const [modalOpen, setModalOpen] = useState(false);

  let moralisMetamask = async () => {
    authenticate({
      signingMessage: "Welcome to Passport!",
    });
  };

  let walletConnectMoralis = async () => {
    authenticate({
      provider: "walletconnect",
      signingMessage: "Welcome to Passport!",
      mobileLinks: [
        "rainbow",
        "metamask",
        "argent",
        "trust",
        "imtoken",
        "pillar",
      ],
    });
  };

  const cancelButtonRef = useRef(null);

  return (
    <div className="h-screen">
      <PassportHeader logout={() => logout()} />
      <div className="h-full min-h-screen relative">
        <div
          className="block h-full w-full md:w-2/5 z-0 absolute right-0 opacity-5"
          style={{
            backgroundImage: "url('/hero.png')",
            backgroundSize: "300px",
            backgroundPosition: "right bottom",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          className="hidden md:block h-full w-2/5 z-0 absolute left-0 opacity-5"
          style={{
            backgroundImage: "url('/hero-flipped.png')",
            backgroundSize: "300px",
            backgroundPosition: "left top",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="flex flex-row max-w-7xl m-auto h-full px-10 gap-10 relative z-10">
          <div className=" w-full md:mx-auto h-full flex justify-center flex-col">
            <div className="flex flex-col gap-1 max-w-3xl">
              <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-center md:text-left">
                Show off your identity with 1 link.
              </h1>
              <p className="text-base md:text-lg mb-6 text-center md:text-left text-gray-600">
                Whether it be displaying your social profiles, NFTs, or crypto
                portfolio, Passport is the only link you’ll ever need.
              </p>
              {!isAuthenticated && (
                <div className="flex flex-col lg:flex-row gap-2 rounded-lg items-start">
                  <button
                    className="py-3 px-8 rounded-full border text leading-6 mb-3 text-white bg-gradient-to-b from-gray-800 to-gray-900 hover:bg-gray-800 transition font-medium shadow hover:from-gray-700 hover:to-gray-800"
                    onClick={() => setModalOpen(!modalOpen)}
                  >
                    Connect Wallet
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className=" hidden md:flex md:h-screen">
            <div className="w-full h-full flex p-0">
              <div
                className="shadow-xl border-black m-auto bg-white overflow-hidden relative"
                style={{
                  width: "290px",
                  height: "597px",
                  borderRadius: "35px",
                  borderWidth: "10px",
                }}
              >
                <div
                  className="bg-black mx-auto rounded-b-2xl absolute top-0 left-0 right-0"
                  style={{
                    width: "95px",
                    height: "20px",
                  }}
                ></div>
                <img src="/sample.png" className="w-full" alt="preview"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={modalOpen}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-72 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
                <div className="bg-white px-8 py-6">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 border-b pb-4 mb-4 border-gray-200"
                      >
                        Connect Wallet
                      </Dialog.Title>
                      {isAuthenticating ? (
                        <div>
                          <p>Initializing Wallet...</p>
                          <div className="mt-4 w-full flex flex-row justify-center">
                            <LoadingSpinner />
                          </div>
                        </div>
                      ) : (
                        <div className="mt-6 flex flex-col w-full gap-1">
                          <button
                            onClick={() => moralisMetamask()}
                            className={buttonStyle}
                          >
                            <span className="w-full text-center">
                              <span className="hidden sm:inline">
                                Connect with
                              </span>{" "}
                              MetaMask
                            </span>
                          </button>
                          <button
                            onClick={() => walletConnectMoralis()}
                            className={buttonStyle}
                          >
                            <span className="w-full text-center">
                              <span className="hidden sm:inline">
                                Connect with
                              </span>{" "}
                              WalletConnect
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default LandingPage;
