import React, { useEffect, useState } from "react";
import { ERC20 } from "../../../types";
import { filterTokenList } from "../../../utils/filterNftList";
import SearchBar from "../../ui-elements/SearchBar";

const DropDownSearch = ({
  tokenList,
  handleTokenClick,
}: {
  tokenList: ERC20[] | "loading";
  handleTokenClick: (token: ERC20) => {};
}) => {
  const [query, setQuery] = useState<string>("");
  const [filteredList, setFilteredList] = useState<ERC20[]>([]);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    if (tokenList !== "loading") {
      setFilteredList(filterTokenList(tokenList, query));
    }
  }, [query, tokenList]);

  return (
    <div className="relative">
      <div className="relative text-gray-500 focus-within:text-gray-600">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="w-5 h-5"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </span>
        <SearchBar
          query={query}
          onChange={setQuery}
          placeholder="Search tokens..."
          setIsSelected={setIsSelected}
        />
      </div>
      {isSelected && filteredList.length > 0 && (
        <div
          className="block absolute z-50 border border-t-0 rounded-b-lg px-1 bg-white w-full max-h-48 overflow-y-scroll py-2"
          onBlur={() => {
            setIsSelected(false);
          }}
        >
          {filteredList.length > 0 ? (
            <ul className="flex flex-col">
              {filteredList.map((token) => (
                <li
                  className="hover:bg-gray-200 rounded flex flex-row cursor-pointer items-center p-1"
                  onClick={() => {
                    handleTokenClick(token);
                    setQuery("");
                    setIsSelected(false);
                  }}
                >
                  <div
                    className=" flex-shrink-0 w-28  text-sm font-black"
                    style={{
                      background:
                        "-webkit-linear-gradient(360deg, #B916B9, #FF7373)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    ${token.symbol}
                  </div>
                  <div className="text-sm font-extrabold">{token.name}</div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <p className="text-sm text-gray-500 py-2">
                No token found for {query}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropDownSearch;
