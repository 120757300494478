import React, { useState, useEffect } from "react";
import { useMoralis, useMoralisFile } from "react-moralis";
import { useMoralisWeb3Api } from "react-moralis";
// import { useNFTBalances } from "react-moralis";

import EditUserDetailsSection from "../components/Settings/EditUserDetailsPanel";
import EditNFTsOnDisplayPanel from "../components/Settings/EditNFTsOnDisplayPanel";
import EditLinksPanel from "../components/Settings/EditLinksPanel";
import PassportHeader from "../components/PassportHeader";
import EditERC20Section from "../components/Settings/EditERC20Panel";
import PhonePreviewScreen from "../components/Settings/PhonePreviewScreen";
import PrimaryButton from "../components/ui-elements/PrimaryButton";
import { shortenEthereumAddress } from "../utils/eth-utils";
import { Tab } from "@headlessui/react";
import EditPanel from "../components/ui-elements/EditPanel";
import { NFT } from "../types";
import { ERC20 } from "../types";
import EditPFPPanel from "../components/Settings/EditPFPPanel";
import EditHeaderPanel from "../components/Settings/EditHeaderPanel";
const h1Style = "text-4xl sm:text-4xl md:text-5xl font-extrabold text-center";
const h2Style = "text-md md:text-lg text-center text-gray-600";
const ethAddressStyle = "font-semibold text-gray-800 border-b border-gray-300";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Settings = () => {
  const { user, setUserData, isUserUpdating } = useMoralis();
  const { saveFile } = useMoralisFile();

  const [pfpFile, setPfpFile] = useState();
  const [headerFile, setHeaderFile] = useState();

  const [erc20s, setErc20s] = useState<ERC20[]>([]);
  const [selectedNFTs, setSelectedNFTs] = useState<NFT[]>([]);

  const [pfpNft, setPfpNft] = useState(null);
  const [headerNft, setHeaderNft] = useState(null);

  const [nftList, setNftList] = useState<NFT[] | "loading">("loading");
  const [tokenList, setTokenList] = useState<ERC20[] | "loading">("loading");
  const Web3Api = useMoralisWeb3Api();
  const [hasCopiedURL, setHasCopiedURL] = useState(false);

  useEffect(() => {
    if (hasCopiedURL) {
      setTimeout(() => setHasCopiedURL(false), 1200);
    }
  });

  const [userDataState, setUserDataState] = useState<UserStateProps>({
    displayName: "",
    bio: "",
    email: "",
    twitter: "",
    instagram: "",
    links: [],
    url_handle: "",
  });

  const deleteLink = (id) => {
    let newLinks = userDataState.links.filter((link) => link.id !== id);

    setUserDataState({ ...userDataState, links: newLinks });
  };

  const setLinks = (newLinks) => {
    setUserDataState({ ...userDataState, links: newLinks });
  };

  interface UserStateProps {
    displayName: string;
    bio: string;
    email: string;
    twitter: string;
    instagram: string;
    links: any;
    url_handle: string;
  }

  useEffect(() => {
    let autoFillUserData = () => {
      if (user) {
        setUserDataState({
          displayName: user.get("displayName"),
          bio: user.get("bio"),
          email: user.get("email"),
          twitter: user.get("twitter") || "",
          instagram: user.get("instagram") || "",
          links: user.get("links") || [],
          url_handle: user.get("url_handle") || "",
        });
        setPfpNft(user.get("pfpNft"));
        setHeaderNft(user.get("headerNft"));
        setErc20s(user.get("erc20s"));
        setSelectedNFTs(user.get("profileNFTs"));
      }
    };
    autoFillUserData();
  }, [user]);

  useEffect(() => {}, [selectedNFTs]);

  useEffect(() => {
    let fetchNFTs = async () => {
      let NFTsFromApi = await Web3Api.account.getNFTs({
        address: user.get("ethAddress"),
      });
      const finalNftList: NFT[] = NFTsFromApi.result;
      setNftList(finalNftList);
    };
    fetchNFTs();

    let fetchTokens = async () => {
      let TokensFromApi: ERC20[] = await Web3Api.account.getTokenBalances({
        address: user.get("ethAddress"),
      });
      setTokenList([
        ...TokensFromApi,
        {
          balance: "0",
          decimals: "0",
          logo: null,
          name: "Ether",
          symbol: "ETH",
          thumbnail: null,
          token_address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
        },
      ]);
    };
    fetchTokens();
  }, [user, Web3Api.account]);

  const submitForm = async () => {
    let newLinks = userDataState.links.filter(
      (link) => link.title.length > 0 && link.url.length > 0
    );
    setUserDataState({ ...userDataState, links: newLinks });
    setUserData({
      ...userDataState,
      profileNFTs: selectedNFTs,
      erc20s: erc20s,
    });
    if (pfpNft) await submitPfpNft();
    else if (pfpFile) await submitPfp();
    if (headerNft) await submitHeaderPfpNft();
    else if (headerFile) await submitHeader();
  };

  const submitPfp = async () => {
    let pfp = await saveFile("", pfpFile);
    user.set("pfp", pfp);
    user.set("pfpNft", null);
    await user.save();
  };

  const submitHeader = async () => {
    let header = await saveFile("", headerFile);
    user.set("header", header);
    user.set("headerNft", null);
    await user.save();
  };

  const submitPfpNft = async () => {
    user.set("pfpNft", pfpNft);
    await user.save();
  };

  const submitHeaderPfpNft = async () => {
    user.set("headerNft", headerNft);
    await user.save();
  };

  return (
    <div>
      <PassportHeader />
      <div className="md:flex md:overflow-hidden block md:h-screen bg-gray-100">
        <div className="px-4 xs:px-5 sm:px-12 md:flex-grow md:overflow-y-scroll md:h-screen md:mt-16">
          <div className="md:max-w-lg lg:max-w-xl md:mx-auto pt-24 md:pt-14">
            <div className="flex flex-col py-8">
              <h1 className={h1Style}>Settings</h1>
              <h2 className={h2Style}>
                Signed in wallet:{" "}
                <span className={ethAddressStyle}>
                  {shortenEthereumAddress(user.get("ethAddress"))}
                </span>
              </h2>
            </div>
            <div className="flex flex-col gap-4 w-full max-w-lg mx-auto">
              <Tab.Group>
                <Tab.List
                  className="flex bg-gray-200 p-0.5 mt-8 shadow-sm"
                  style={{ borderRadius: "6px" }}
                >
                  <Tab
                    style={{ borderRadius: "6px" }}
                    className={({ selected }) =>
                      classNames(
                        "w-full text-sm font-normal rounded-lg h-7",
                        "focus:outline-none ring-opacity-60",
                        selected
                          ? "bg-white shadow-sm text-gray-900"
                          : "text-gray-700 hover:bg-white/[0.12] hover:text-gray-900 focus:ring-2 ring-offset-2 ring-offset-gray-200 ring-white"
                      )
                    }
                  >
                    On Display
                  </Tab>
                  <Tab
                    style={{ borderRadius: "6px" }}
                    className={({ selected }) =>
                      classNames(
                        "w-full text-sm font-normal rounded-lg h-7",
                        "focus:outline-none ring-opacity-60",
                        selected
                          ? "bg-white shadow-sm text-gray-900"
                          : "text-gray-700 hover:bg-white/[0.12] hover:text-gray-900 focus:ring-2 ring-offset-2 ring-offset-gray-200 ring-white"
                      )
                    }
                  >
                    Edit Profile
                  </Tab>
                </Tab.List>
                <Tab.Panels className="w-full bg-white px-4 py-6 rounded-md shadow mb-48 md:px-5">
                  <div>
                    <Tab.Panel className="flex flex-col gap-4">
                      <EditPanel
                        text="Add your favorite tokens."
                        subtext="Limited to 8 currently."
                      >
                        {/* <p>{JSON.stringify(tokenList)}</p> */}
                        <EditERC20Section
                          tokenList={tokenList}
                          selectedTokens={erc20s}
                          setSelectedTokens={setErc20s}
                        />
                      </EditPanel>
                      <hr />
                      <EditPanel
                        text="Showcase your favorite NFTs."
                        subtext="Limited to 3 currently."
                      >
                        <EditNFTsOnDisplayPanel
                          nftList={nftList}
                          selectedNFTs={selectedNFTs}
                          setSelectedNFTs={setSelectedNFTs}
                        />
                      </EditPanel>
                      <hr />
                      <EditPanel text="Add your links.">
                        <EditLinksPanel
                          deleteLink={deleteLink}
                          setLinks={setLinks}
                          links={userDataState.links}
                          order="5"
                        />
                      </EditPanel>
                    </Tab.Panel>
                    <Tab.Panel className="flex flex-col gap-4">
                      <EditPanel
                        text="Choose a profile picture."
                        subtext="Recommended size 400x400px"
                      >
                        <EditPFPPanel
                          nftList={nftList}
                          setPfpFile={setPfpFile}
                          pfpFile={pfpFile}
                          pfpNft={pfpNft}
                          setPfpNft={setPfpNft}
                        />
                      </EditPanel>
                      <hr />
                      <EditPanel
                        text="Choose a header."
                        subtext="Recommended size 1400x400px"
                      >
                        <EditHeaderPanel
                          nftList={nftList}
                          setHeaderNft={setHeaderNft}
                          headerNft={headerNft}
                          setHeaderFile={setHeaderFile}
                          headerFile={headerFile}
                        />
                      </EditPanel>
                      <hr />
                      <EditPanel text="Fill out some details.">
                        <EditUserDetailsSection
                          submitEditProfileForm={submitForm}
                          formData={userDataState}
                          setFormData={setUserDataState}
                        />
                      </EditPanel>
                    </Tab.Panel>
                    <div className="flex w-full mt-4">
                      <PrimaryButton
                        text="Save Changes"
                        onClick={submitForm}
                        disabled={isUserUpdating}
                        loading={isUserUpdating}
                      />
                    </div>
                  </div>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
        <div className="w-96 xl:w-1/3 hidden md:flex md:h-screen md:mt-16">
          <div className="shadow-inner w-full h-full bg-gray-300 flex flex-col p-0">
            <div className="bg-white w-full h-12 flex gap-2 items-center px-3 text-sm font-medium border-b border-gray-200">
              <span className="whitespace-nowrap">Your URL: </span>
              <a
                href={"/" + user.get("ethAddress")}
                target="_blank"
                rel="noopener noreferrer"
                className=" font-normal underline w-full overflow-ellipsis overflow-hidden whitespace-nowrap"
              >
                {"http://trypassport.xyz/" + user.get("ethAddress")}
              </a>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(
                    "http://trypassport.xyz/" + user.get("ethAddress")
                  );
                  setHasCopiedURL(true);
                }}
                className={`border py-1 px-2.5 rounded-md shadow-sm   text-sm  active:bg-red-500 ${
                  hasCopiedURL
                    ? "disabled cursor-default"
                    : "hover:border-gray-400 transition transform"
                }`}
              >
                {hasCopiedURL ? "Copied!" : "Copy"}
              </button>
            </div>
            <PhonePreviewScreen
              user={{
                ...userDataState,
                pfpFile,
                erc20s,
                selectedNFTs,
                pfpNft,
                headerNft,
                ethAddress: user.get("ethAddress"),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
