import React from "react";
import { getURLfromNFTMetadata } from "../../../../utils/eth-utils";

const NFTSearchItem = ({ nftIsSelected, nft, onClick }) => {
  if (!nft) return <></>;
  const parsedMetadata = JSON.parse(nft.metadata);
  if (parsedMetadata == null) return <></>;

  const nftImageUrl = getURLfromNFTMetadata(
    parsedMetadata ? parsedMetadata.image : undefined
  );
  if (!nftImageUrl) return <></>;

  return (
    <button
      onClick={onClick}
      className={`relative overflow-hidden min-w-min ${
        nftIsSelected && "border-4 border-blue-500"
      } rounded-sm`}
      style={{
        width: "105px",
        height: "105px",
        minWidth: "105px",
        minHeight: "105px",
      }}>
      {nftImageUrl ? (
        <img
          alt={JSON.parse(nft.metadata).name}
          src={nftImageUrl}
          style={{ aspectRatio: "1/1" }}
          className="w-full block object-cover"
        />
      ) : (
        <div className="w-full h-full bg-gradient-to-b from-blue-400 to-blue-500 text-white font-semibold flex items-center justify-center text-sm p-2">
          {JSON.parse(nft.metadata).name ||
            JSON.parse(nft.metadata).collection_name}
        </div>
      )}
    </button>
  );
};

export default NFTSearchItem;
