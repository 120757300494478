import React, { useState } from "react";
import PFPNFTSelector from "../../PFPNFTSelector";
import ProfilePicture from "../../ProfilePicture/ProfilePicture";
import SecondaryButton from "../../ui-elements/SecondaryButton";

const PfpControls = ({
  nftList,
  setPfpFile,
  setPfpNft,
  setNftDrawerOpen,
  nftDrawerOpen,
}) => {
  let isButtonDisabled = nftList.length === 0;

  let uploadPfpImage = () => {
    document.getElementById("uploadPfpImageButton").click();
    document.getElementById("uploadPfpImageButton").onchange = () => {
      setPfpFile(
        (document.getElementById("uploadPfpImageButton") as HTMLInputElement)
          .files[0]
      );
      setPfpNft(null);
    };
  };

  return (
    <div className="text-center flex flex-col w-full h-full justify-center">
      <SecondaryButton
        onClick={() => setNftDrawerOpen(!nftDrawerOpen)}
        disabled={isButtonDisabled}
        text={isButtonDisabled ? "You have no NFTs!" : "Select NFT"}
      ></SecondaryButton>

      <input
        type="file"
        hidden
        id="uploadPfpImageButton"
        accept="image/png,image/jpeg"
      />
      <SecondaryButton
        text={"Upload Image"}
        variant="borderless"
        onClick={() => uploadPfpImage()}
      ></SecondaryButton>
    </div>
  );
};

const EditPFPSection = ({
  nftList,
  setPfpFile,
  pfpFile,
  pfpNft,
  setPfpNft,
}) => {
  const [nftDrawerOpen, setNftDrawerOpen] = useState(false);
  return (
    <div className="">
      <div
        className="flex justify-between max-w-xs mx-auto"
        style={{ padding: "8px 16px" }}
      >
        <div className="flex-none flex-grow-0">
          <div className="w-32">
            <ProfilePicture {...{ pfpFile }} {...{ pfpNft }} />
          </div>
        </div>
        <div className="flex-none flex-grow-0">
          <PfpControls
            {...{ nftList }}
            {...{ setPfpFile }}
            {...{ setPfpNft }}
            {...{ setNftDrawerOpen }}
            {...{ nftDrawerOpen }}
          />
        </div>
      </div>
      {nftDrawerOpen && (
        <PFPNFTSelector
          nftList={nftList}
          setPfpNft={setPfpNft}
          setNftDrawerOpen={setNftDrawerOpen}
        />
      )}
    </div>
  );
};

export default EditPFPSection;
